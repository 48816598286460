.nav-container {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  background: var(--tertiary100);

  display: flex;
  align-items: center;

  border-bottom: 2px solid black;
}

.nav-image-wrapper {
  margin-left: 5%;
}

.nav-image {
  height: 50px;
}
