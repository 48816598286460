.home-info-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.hero-img {
  width: 100%;
}

.home-text-wrapper {
  padding: 13px;
  font-weight: 500;
}

.home-text-title {
  font-size: 40px;
}

.home-text-desc {
  font-family: "Heebo";
}

.home-text-wrapper > * {
  padding: 5px 10%;
}

.button-format-wrapper {
  display: flex;
  flex-direction: column;
}

.button-format-wrapper > * {
  margin: 10px 10px 10px 0;
  position: relative;
  display: inline-block;
}

.home-coupon-wrapper {
  height: 100px;
  margin-bottom: 30px;
}

.home-coupon {
  position: absolute;
  width: 70px;
  height: 75px;
  top: 30px;
  left: -20px;
  padding: 5px 10px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: larger;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  transform: rotate(-35deg);
}

@media screen and (min-width: 600px) {
  .button-format-wrapper {
    display: flex;
    flex-direction: row;
  }

  .home-text-wrapper {
    margin-top: 30px;
  }
}

@media (min-width: 950px) and (max-width: 1339px) {
  .home-info-wrapper {
    display: flex;
    flex-direction: row;
  }

  .home-info-wrapper > * {
    display: flex;
    flex: 1;
  }

  .hero-img-wrapper {
    width: 100%;
  }

  .hero-img {
    height: 100%;
  }

  .home-text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .home-text-wrapper > * {
    padding: 5px 10%;
  }

  .home-text-title {
    font-size: 350%;
    line-height: 90px;
  }

  .home-text-desc {
    font-size: 16px;
    font-weight: 500;
  }
}

@media (min-width: 1340px) {
  .home-info-wrapper {
    display: flex;
    flex-direction: row;
    height: 72.3vh;
  }

  .home-info-wrapper > * {
    display: flex;
    flex: 1;
  }

  .hero-img {
    object-fit: cover !important;
  }

  .home-text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .home-text-wrapper > * {
    padding: 5px 10%;
  }

  .home-text-desc {
    font-size: 20px;
    font-weight: 500;
  }

  .home-coupon {
    font-size: 28px;
  }
}

@media (min-width: 1340px) and (max-width: 1649px) {
  .home-text-title {
    font-size: 350%;
    margin-top: 20px;
  }
}

@media (min-width: 1650px) {
  .home-text-title {
    font-size: 450%;
  }
}
