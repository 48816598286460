.delivery-hero-wrapper {
  display: flex;
  flex-direction: column-reverse;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  align-items: center;
  justify-content: center;
}

.delivery-hero-title {
  display: flex;
  justify-content: center;
  font-size: 2.4rem;
  width: 100%;
  margin: 20px 0;
  line-height: 60px;
  text-transform: uppercase;
}

.delivery-hero-title-child {
  width: 80%;
}

.delivery-hero-img {
  width: 100%;
  height: fit-content;
}

@media (min-width: 917px) {
  .delivery-hero-wrapper {
    flex-direction: row;
  }
  .delivery-hero-img {
    width: 50%;
    height: fit-content;
  }
}

@media (min-width: 917px) and (max-width: 1389px) {
  .delivery-hero-title {
    font-size: 4.1vw;
  }
  .delivery-hero-title-child {
    width: 80%;
  }
}

@media (min-width: 1389px) and (max-width: 2299px) {
  .delivery-hero-title {
    font-size: 4.5rem;
    line-height: 80px;
  }
}

@media (min-width: 2300px) {
  .delivery-hero-title {
    font-size: 7rem;
    line-height: normal;
  }
  .delivery-hero-title-child {
    width: 90%;
  }
}
