@import url("https://fonts.googleapis.com/css2?family=Anton:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

body {
  margin: 0;
  font-family: "Anton", "Heebo", "Inter", sans-serif;
  --primary100: black;
  --secondary100: #ff700a;
  --tertiary100: #fffdfa;
  background: var(--tertiary100);
}
