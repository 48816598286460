.footer-wrapper {
  background: var(--primary100);
  color: var(--tertiary100);
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px;
}

.footer-title {
  font-size: 40px;
}

.footer-text-wrapper {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  font-family: "Inter";
}

.powered-style {
  width: 50%;
}

@media (min-width: 560px) {
  .powered-style {
    width: 200px;
  }
}
