.delivery-info-wrapper {
  padding: 30px 50px;
  margin-top: 40px;
}

.delivery-info-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.coupon-wrapper,
.coupon-btn {
  background: var(--primary100);
  color: var(--tertiary100);
}

.coupon-wrapper {
  background: var(--primary100);
  color: var(--tertiary100);

  position: fixed;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 120px;

  font-size: 5vw;
}

.coupon-wrapper-text {
  margin-left: 20px;
  width: 50%;
  text-transform: uppercase;
}

.coupon-btn {
  position: relative !important;
  margin-right: 15px;

  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

@media (min-width: 560px) {
  .coupon-wrapper {
    font-size: 25px;
    height: 110px;
  }

  .delivery-info-wrapper {
    margin-top: 50px;
  }

  .coupon-wrapper-text {
    justify-content: flex-start;
    margin-left: 5%;
  }
}
