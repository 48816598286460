.nav-wrapper {
  text-decoration: none;
}

.nav-wrapper::-webkit-textfield-decoration-container {
  text-decoration: none;
  -webkit-text-decoration-line: line-through;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: start;
  cursor: pointer;
  border: 2px solid;
  border-radius: 25px;
  padding: 5px 10px;
  gap: 5px;
  text-transform: uppercase;
  border-color: var(--secondary100);
  background: var(--tertiary100);
}

.btn-text {
  font-family: "Heebo";
  font-weight: 700;
  line-height: 22px;
}

.arrow-style {
  font-size: 15px;
  font-weight: bold;
  color: var(--secondary100);
}
